.location-search-input {
    display: flex;
    flex: 1;
    border-radius: 0.5rem;
    border: none;
    padding: 1rem;
    font-family: Montserrat;
    border: 1px solid #E4EAF0;
    color: #858ea8;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
  }

  .autocomplete-dropdown-container {
    position: absolute;
    z-index: 1000;
    width: 100%; /* Ensure dropdown takes full width of parent */
    background-color: #fff;
    border-radius: 0.5rem;
    margin-top: 5px;
  }

  .suggestion-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
  }

  .suggestion-item:last-child {
    border-bottom: none;
  }

  .suggestion-item--active {
    background-color: #f0f0f0;
  }