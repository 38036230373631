
  
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;

  }


  
.footer-content {
    flex: 1; 
    background-color: #195162;
    background-image:  url('../../assets/img/background__footer.png'); 
    background-position: center;
    background-size: contain;
    background-repeat: repeat;
    margin: 0 auto;
    padding: 20px ;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;

} 
.footer-bottom {
    flex: 1; 
    background-color: white;
   
}
.presentation {
    max-width: 300px;
  }
  
  .logo {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 1rem;
  }
  
  .presentation-text {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.5;
    color: #fff;
  }
  
  .sub-text {
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
    color:#fff;
  }
  
  .commander-btn {
    background-color: #5BBCEA;
    color: white;
    border: none;
    padding: 0.6rem 2rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;
  }
  
  .commander-btn:hover {
    background-color: #00B4E6;
  }
  
.footer-title {
font-size: 18px;
color: #fff;
font-family: "Montserrat";
line-height:35.02px;
font-weight: 500;
}

.footer-section h3 {
    color: white;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.75rem;
  }
  
  .footer-section ul li a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #00C8FF;
  }
  

  .info-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .info-item .icon {
    color: #5BBCEA;
    margin-top: 3px;
  }
  
  .info-item p {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  /* Newsletter Form */
  .newsletter-form {
    display: flex;
    gap: 0.5rem;
  }
  
  .newsletter-input {
    flex: 1;
    padding: 0.6rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: white;
    font-size: 0.9rem;
  }
  
  .newsletter-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  
  .newsletter-input:focus {
    outline: none;
    border-color: #00C8FF;
    background: rgba(255, 255, 255, 0.15);
  }
  
  .newsletter-btn {
    background-color: #5BBCEA;
    color: white;
    border: none;
    width: 42px;
    height: 42px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .newsletter-btn:hover {
    background-color: #00B4E6;
  }
  
 
  
  .footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
  }
  
  .footer-links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .footer-links a {
    color: #606060;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #00C8FF;
  }
  
  .separator {
    color:#5BBCEA;
  }
  
 
  @media (max-width: 1200px) {
    .footer-content {
      grid-template-columns: repeat(4, 1fr);
    }
    .presentation {
      grid-column: span 4;
      max-width: 100%;
    }
  }
  
  @media (max-width: 992px) {
    .footer-content {
      grid-template-columns: repeat(3, 1fr);
    }
    .presentation {
      grid-column: span 3;
    }
  }
  
  @media (max-width: 768px) {
    .footer-content {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
    .presentation {
      grid-column: span 2;
    }
  }
  
  @media (max-width: 576px) {
    .footer-content {
      grid-template-columns: 1fr;
    }
    .presentation {
      grid-column: span 1;
    }
    .footer-bottom-content {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  }