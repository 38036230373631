/* Header styles */
.header {
  width: 100%;
  display: flex; 
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header .part1 {
  flex: 0 0 25%;
  background: #5BBCEA;
  padding: 15px 60px 15px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .part2 {
  flex: 1; 
  display: flex; 
  flex-direction: column;
}

.top-bar {
  color: white;
  padding: 8px 0;
  background-color: #195162;
  height: 60px;
  display: flex;
  justify-content: flex-end;
}

.top-bar-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: 13px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: 16px;
  font-family: "Montserrat";
  line-height:19.5px;
  font-weight: 500;
}

.command-button {
  width: 297.72px;
  height: 48px;
  background: #5BBCEA;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat";
  line-height:19.5px;
  clip-path: polygon(0 0, 92% 0, 100% 50%, 92% 100%, 0 100%);
  min-width: 260px;
  
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.command-button:hover {
  background: linear-gradient(45deg, #00C4EB, #0093C0);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.3) inset,
    0 0 20px rgba(0, 180, 219, 0.7);
}



.main-nav {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.nav-content {
    height: 70px;
    max-width: 1200px;
    margin: 0 auto;
    height: 70px;

}



.logo {
  height: 60px;
  width: auto;
  filter: brightness(0) invert(1);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 100px;
  height: 100%;
  padding-right: 24px;
  padding-left: 24px;

}

.nav-link {
  color: #333;
  text-decoration: none;
  transition: all 0.2s;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-family: "Montserrat";
  line-height:19.5px;
}



.nav-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #00B4DB;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link:hover::after {
  transform: scaleX(1);
}



.nav-link.active::after {
  transform: scaleX(1);
}


.login-menu {
  position: absolute;
  right: 1rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem; 
  background-color: white;
  padding: 1rem; 
  border-radius: 0.5rem; 
  z-index: 1000;
  top: 100%; /* Juste en dessous du bouton */
  right: 24px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}

.client-button {
  width: 186px;
  height: 41px;
  border-radius: 8px; 
  background-color: #EAB15B;
  color: white;
  border: none;
  font-weight: 500; 
  font-size: 16px;
  font-family: "Montserrat";
  line-height:19.5px;
  transition: background-color 0.3s;
}

.client-button:hover {
  background-color: #D89E4E; 
}


.transporter-button {
  width: 186px;
  height: 41px;
  border-radius: 8px; 
  background-color: #5BBCEA; 
  border:none;
  color: white;
  font-weight: 500; 
  font-size: 16px;
  font-family: "Montserrat";
  line-height:19.5px;
  transition: background-color 0.3s;
}

.transporter-button:hover {
  background-color: #00B4E6; 
}
.login-connect-button {
 border: none;
 background-color: white;
}